import {
  FormControlLabel,
  Grid,
  Modal,
  Radio,
  RadioGroup,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import {
  sendKmsRedux,
  startAcumuladorAño,
  startAcumuladorGNC,
  startAcumuladorMarca,
  startAcumuladorModelo,
  startAcumuladorVersion,
} from "../../actions/pasosCotizar";
import { ModalCotizar } from "../ModalCotizar/ModalCotizar";

export const ModalCarManual = ({ open, setOpen }) => {
  const [openModalManual, setopenModalManual] = useState();
  const handleClose = () => setOpen(false);

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const years = [
    2022, 2021, 2020, 2019, 2018, 2017, 2016, 2015, 2014, 2013, 2012, 2011,
    2010, 2009, 2008, 2007, 2006, 2005, 2004, 2003, 2002, 2001, 2000,
  ];

  const sendDataManual = (data) => {
    setopenModalManual(true);
    dispatch(startAcumuladorAño(data.año));
    dispatch(startAcumuladorMarca(data.marca, ""));
    dispatch(startAcumuladorModelo(data.modelo, ""));
    dispatch(startAcumuladorVersion(data.version, ""));
    dispatch(sendKmsRedux(data.kilometros));
    dispatch(startAcumuladorGNC(data.gnc));
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title-2"
        aria-describedby="modal-modal-description-2"
      >
        <div className="modalCarManual">
          <p className="titleModalCarManual">Ingresa los datos manualmente</p>
          <form onSubmit={handleSubmit(sendDataManual)}>
            <Grid style={{ width: "100%", marginTop: "20px" }}>
              <p className="labelModalManual">Año</p>
              <select
                className="selectAñoModalManual"
                {...register("año", { required: true })}
              >
                {years.map((item, i) => (
                  <option key={i}>{item}</option>
                ))}
              </select>
              {errors.año && errors.año.type === "required" && (
                <p className="text-error">El año es un campo obligatorio.</p>
              )}
            </Grid>
            <Grid style={{ width: "100%", marginTop: "20px" }}>
              <p className="labelModalManual">Marca</p>
              <input
                className="inputModalAño"
                placeholder="Marca"
                {...register("marca", { required: true })}
              />
              {errors.marca && errors.marca.type === "required" && (
                <p className="text-error" style={{ marginLeft: "0px" }}>
                  La marca es un campo obligatorio.
                </p>
              )}
            </Grid>
            <Grid style={{ width: "100%", marginTop: "20px" }}>
              <p className="labelModalManual">Modelo</p>
              <input
                className="inputModalAño"
                placeholder="Modelo"
                {...register("modelo", { required: true })}
              />
              {errors.modelo && errors.modelo.type === "required" && (
                <p className="text-error" style={{ marginLeft: "0px" }}>
                  El modelo es un campo obligatorio.
                </p>
              )}
            </Grid>
            <Grid style={{ width: "100%", marginTop: "20px" }}>
              <p className="labelModalManual">Versión</p>
              <input
                className="inputModalAño"
                placeholder="Versión"
                {...register("version", { required: true })}
              />
              {errors.version && errors.version.type === "required" && (
                <p className="text-error" style={{ marginLeft: "0px" }}>
                  La versión es un campo obligatorio.
                </p>
              )}
            </Grid>
            <Grid style={{ width: "100%", marginTop: "20px" }}>
              <p className="labelModalManual">Kilómetros</p>
              <input
                className="inputModalAño"
                placeholder="Kilometraje"
                {...register("kilometros", { required: true })}
                type="number"
              />
              {errors.kilometros && errors.kilometros.type === "required" && (
                <p className="text-error" style={{ marginLeft: "0px" }}>
                  El kilometraje es un campo obligatorio.
                </p>
              )}
            </Grid>
            <Grid style={{ width: "100%", marginTop: "20px" }}>
              <p className="labelModalManual">¿Tiene o tuvo GNC?</p>
              <Grid>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  {...register("gnc")}
                >
                  <Grid>
                    <FormControlLabel
                      value="Si"
                      control={<Radio />}
                      label="Si"
                      defaultChecked
                    />
                    <FormControlLabel
                      value="No"
                      control={<Radio />}
                      label="No"
                    />
                  </Grid>
                </RadioGroup>
              </Grid>
              <Grid
                style={{
                  width: "100%",
                  marginTop: "10px",
                }}
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <button
                  className="btnClose"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  Cerrar
                </button>
                <button className="btnCotizar" type="submit">
                  Cotizar
                </button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Modal>
      <ModalCotizar
        openModalManual={openModalManual}
        setopenModalManual={setopenModalManual}
      />
    </>
  );
};
