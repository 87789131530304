import { Modal } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import IconWsp from "../../images/icon-whatsapp.png";
import React, { useEffect, useState } from "react";
import {
  enviarDatosAuto,
  postGoogleSheet,
  setState,
  startLoading,
} from "../../actions/pasosCotizar";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ModalStopperComponent } from "../ModalStopper/ModalStopperComponent";
import { ModalClientInterior } from "../ModalClientInterior/ModalClientInterior";

export const ModalCotizar = ({ openModalManual, setopenModalManual }) => {
  const { acumulador, valorKms, telefono, loading, status } = useSelector(
    (state) => state.inputReducer
  );
  const [showModalStopper, setShowModalStopper] = useState(false);
  const [showModalClient, setShowModalClient] = useState(false);

  const dispatch = useDispatch();

  const [codArea, setcodArea] = useState(8);
  const [celular, setCelular] = useState("");
  const [nameUser, setNameUser] = useState("");

  const handleClose = () => {
    if (
      telefono !== undefined &&
      (!loading ||
        loading === "invalid cel" ||
        loading === "No se encontró el user en la db")
    ) {
      dispatch(setState(true));
      setopenModalManual(false);
    } else {
      setopenModalManual(false);
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const navigate = useNavigate();

  const sendData = () => {
    enviarDatosAuto(
      dispatch,
      acumulador.marca,
      acumulador.brand_id,
      acumulador.modelo,
      acumulador.model_id,
      acumulador.version,
      acumulador.version_id,
      acumulador.año,
      acumulador.gnc,
      valorKms,
      celular,
      nameUser,
      setopenModalManual,
      setShowModalStopper,
      navigate
    );
    postGoogleSheet(
      acumulador.marca,
      acumulador.modelo,
      acumulador.version,
      acumulador.año,
      valorKms,
      nameUser,
      celular,
      acumulador.gnc
    );
    dispatch(startLoading(celular, true));
    reset();
  };

  const onSubmit = (data) => {
    const telefono = `549${data.cod_area}${data.telefono}`;
    setcodArea(data.cod_area);
    setCelular(telefono);
    setNameUser(data.nombre);
    if (data.cod_area !== "11") {
      setShowModalClient(true);
    } else {
      enviarDatosAuto(
        dispatch,
        acumulador.marca,
        acumulador.brand_id,
        acumulador.modelo,
        acumulador.model_id,
        acumulador.version,
        acumulador.version_id,
        acumulador.año,
        acumulador.gnc,
        valorKms,
        telefono,
        data.nombre,
        setopenModalManual,
        setShowModalStopper,
        navigate
      );
      postGoogleSheet(
        acumulador.marca,
        acumulador.modelo,
        acumulador.version,
        acumulador.año,
        valorKms,
        data.nombre,
        telefono,
        acumulador.gnc
      );
      dispatch(startLoading(telefono, true));
      reset();
    }
  };

  return (
    <>
      <Modal
        open={openModalManual}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          {telefono === undefined ? (
            <div className="containerModalCotizar">
              <p className="title-modal-cotizar">Solo nos faltan tus datos.</p>
              <form onSubmit={handleSubmit(onSubmit)}>
                <p className="label-input">Nombre completo</p>
                <input
                  className="input-datos"
                  placeholder="Ingrese su nombre completo"
                  {...register("nombre", {
                    required: true,
                    pattern:
                      /^[^\uD800-\uDBFF\uD800-\uDBFF\uDC00-\uDFFF\uDC00-\uDFFF]*$/,
                  })}
                />
                {errors.nombre && (
                  <p className="text-error">Este campo es requerido</p>
                )}
                {errors.nombre && errors.nombre.type === "pattern" && (
                  <p className="text-error">Los emojis no están permitidos</p>
                )}
                <p className="label-input">Teléfono celular</p>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                    width: "100%",
                    marginLeft: "10px",
                    marginTop: "-10px",
                  }}
                >
                  <p>+549</p>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "center",
                      flexDirection: "column",
                      marginLeft: "10px",
                    }}
                  >
                    <input
                      className="input-datos"
                      style={{
                        width: "45px",
                        marginRight: "10px",
                        marginTop: "15px",
                      }}
                      placeholder="Ej: 11"
                      type="number"
                      {...register("cod_area", {
                        required: true,
                        maxLength: 4,
                      })}
                      onChange={(e) => {
                        setcodArea(e.target.value.length);
                      }}
                    />
                    <p className="description-input">Cod. Área</p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "center",
                      flexDirection: "column",
                      marginLeft: "10px",
                    }}
                  >
                    <input
                      className="input-datos"
                      style={{
                        marginRight: "10px",
                        marginTop: "15px",
                      }}
                      placeholder="Ej: Sin 15"
                      type="number"
                      {...register("telefono", {
                        required: true,
                        maxLength: Math.abs(codArea - 10),
                        minLength: Math.abs(codArea - 10),
                      })}
                      maxLength={Math.abs(parseInt(codArea?.length) - 10)}
                      minLength={Math.abs(parseInt(codArea?.length) - 10)}
                    />
                    <p className="description-input">Tu número</p>
                  </div>
                </div>
                <div
                  style={{
                    marginTop: "15px",
                    width: "100%",
                  }}
                >
                  {errors.cod_area && errors.cod_area.type === "required" && (
                    <p className="text-error">
                      El código de area es requerido.
                    </p>
                  )}
                  {errors.cod_area && errors.cod_area.type === "maxLength" && (
                    <p className="text-error">
                      El código de area es debe contener maximo 4 dígitos.
                    </p>
                  )}
                  {errors.telefono && errors.telefono.type === "maxLength" && (
                    <p className="text-error">
                      El número de teléfono debe ser de {Math.abs(codArea - 10)}{" "}
                      dígitos y recuerda, sin 15.
                    </p>
                  )}
                  {errors.telefono && errors.telefono.type === "minLength" && (
                    <p className="text-error">
                      El número de teléfono debe ser de {Math.abs(codArea - 10)}{" "}
                      dígitos y recuerda, sin 15.
                    </p>
                  )}
                  {errors.telefono && errors.telefono.type === "required" && (
                    <p className="text-error">
                      El número de télefono es requerido.
                    </p>
                  )}
                </div>
                <button className="btn-cotizar-activo" type="submit">
                  Cotizar
                </button>
              </form>
            </div>
          ) : telefono !== undefined && loading === true ? (
            <div className="containerModalCotizar">
              <div
                style={{
                  marginBottom: "40px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p className="titleInicial">Espere un momento ...</p>
                <CircularProgress />
              </div>
            </div>
          ) : (loading !== true || loading !== false) && status !== 200 ? (
            <div className="containerModalCotizar">
              <p
                className="titleInicial"
                style={{ marginBottom: "20px", textAlign: "center" }}
              >
                {loading}
              </p>
              <button className="btn-wsp">
                <a
                  href="https://wa.me/5491138876132"
                  target="_blank"
                  style={{
                    textDecoration: "none",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "white",
                  }}
                >
                  <img src={IconWsp} alt="icon-wsp" className="wsp-icon" />
                  Consultar
                </a>
              </button>
            </div>
          ) : null}
        </>
      </Modal>
      <ModalClientInterior
        showModalClient={showModalClient}
        setShowModalClient={setShowModalClient}
        sendData={sendData}
        setopenModalManual={setopenModalManual}
      />
      <ModalStopperComponent
        showModalStopper={showModalStopper}
        setShowModalStopper={setShowModalStopper}
        setopenModalManual={setopenModalManual}
      />
    </>
  );
};
