import React from "react";
import { Modal } from "@mui/material";
import {
  sendKmsRedux,
  startAcumuladorAño,
  startAcumuladorMarca,
  startAcumuladorModelo,
  startAcumuladorVersion,
  startGetBrands,
  startGetModels,
  startGetVersion,
  startLoading,
} from "../../actions/pasosCotizar";
import { useDispatch } from "react-redux";
import { isTablet } from "../../actions/pasosTablet";
import { useMediaQuery } from "../../hooks/useMediaQuery";

export const ModalClientInterior = ({
  showModalClient,
  setShowModalClient,
  sendData,
  setopenModalManual,
}) => {
  let isPageWide = useMediaQuery("(min-width: 900px)");

  const dispatch = useDispatch();

  const backToHome = () => {
    if (isPageWide) {
      dispatch(startAcumuladorAño(undefined));
      dispatch(startAcumuladorMarca(undefined));
      dispatch(startAcumuladorModelo(undefined));
      dispatch(startAcumuladorVersion(undefined));
      dispatch(sendKmsRedux(undefined));
      dispatch(startGetBrands([]));
      dispatch(startGetModels([]));
      dispatch(startGetVersion([]));
      dispatch(startLoading(undefined, false, 100));
    } else {
      dispatch(isTablet(""));
      dispatch(startAcumuladorAño(""));
      dispatch(startAcumuladorMarca(""));
      dispatch(startAcumuladorModelo(""));
      dispatch(startAcumuladorVersion(""));
      dispatch(sendKmsRedux(""));
      dispatch(startGetBrands([]));
      dispatch(startGetModels([]));
      dispatch(startGetVersion([]));
      dispatch(startLoading(undefined, false, 100));
    }
    setShowModalClient(false);
    setopenModalManual(false);
  };

  const handleClose = () => {
    backToHome();
    setShowModalClient(false);
  };

  return (
    <>
      <Modal
        open={showModalClient}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="modal-stopper">
          <div className="top-modal-stopper">
            <p
              className="title-modal-stopper"
              style={{ fontSize: isPageWide ? "30px" : "21px" }}
            >
              ¿Vivis en el interior del país?
            </p>
            <div className="d-flex flex-column align-items-center justify-content-center">
              {isPageWide ? (
                <>
                  <p
                    className="description-modal-stopper"
                    style={{ fontSize: "20px" }}
                  >
                    Por el momento, solo realizamos inspecciones en <br />
                    la Ciudad Autónoma de Buenos Aires (CABA).
                  </p>
                  <p className="text-footer-modal-client">
                    Agradecemos tu comprensión.
                  </p>
                </>
              ) : (
                <>
                  <p
                    className="description-modal-stopper-mobile"
                    style={{ fontSize: "14px" }}
                  >
                    Por el momento, solo realizamos <br /> inspecciones en la
                    Ciudad Autónoma de Buenos Aires (CABA).
                  </p>
                  <p
                    className="text-footer-modal-client"
                    style={{ fontSize: "14px" }}
                  >
                    Agradecemos tu comprensión.
                  </p>
                </>
              )}
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            {isPageWide ? (
              <>
                <button className="btn-continue-client" onClick={backToHome}>
                  Entiendo :(
                </button>
                <button
                  className="btn-modal-client"
                  onClick={() => {
                    sendData();
                    setShowModalClient(false);
                  }}
                >
                  ¡Avancemos :)!
                </button>
              </>
            ) : (
              <>
                <button
                  className="btn-modal-client"
                  onClick={() => {
                    sendData();
                    setShowModalClient(false);
                  }}
                >
                  ¡Avancemos :)!
                </button>
                <button className="btn-continue-client" onClick={backToHome}>
                  Entiendo :(
                </button>
              </>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};
