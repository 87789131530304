export const types = {
  InputComportamiento: "[Input] InputComportamiento",
  AcumuladorAño: "[Input] Acumulador Año",
  AcumuladorMarca: "[Input] Acumulador Marca",
  AcumuladorModelo: "[Input] Acumulador Modelo",
  AcumuladorVersion: "[Input] Acumulador Version",
  AcumuladorKilometros: "[Input] Acumulador Kilometros",
  AcumuladorGNC: "[Input] Acumulador GNC",
  GetYears: "[Input] Get Years",
  GetBrands: "[Input] Get Brands",
  GetModels: "[Input] Get Models",
  GetVersion: "[Input] Get Version",
  SendKms: "[Input] Send Kms",
  EditarAuto: "[Validate] Editar Auto",
  DatosAuto: "[Validate] Datos Auto",
  EsTablet: "[Input] Es Tablet ",
  EsCelular: "[Input] Es Celular ",
  CloseKms: "[Input] Cerrar Kms",
  EstaActivo: "[Input] Activo Kms",
  Loading: "[Input] Start Loading",
  SetState: "[Input] Set State",
};
