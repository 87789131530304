import React from "react";
import { useSelector } from "react-redux";
import { Modal } from "@mui/material";

import { ModalCotizar } from "../ModalCotizar/ModalCotizar";

export const BotonContinuarTablet = () => {
  const { acumulador, valorKms } = useSelector((state) => state.inputReducer);

  const [open, setOpen] = React.useState(false);
  const [openModalKmsLow, setopenModalKmsLow] = React.useState(false);

  const handleCloseModalKmsLow = () => {
    setopenModalKmsLow(false);
  };

  const enviarDatos = () => {
    const valorKmsFormated = parseInt(valorKms.replace(/\D/g, ""));
    if (valorKmsFormated < 1000) {
      setopenModalKmsLow(true);
    } else {
      setOpen(true);
    }
  };

  return (
    <>
      {valorKms !== undefined &&
      valorKms > 0 &&
      acumulador.gnc !== undefined &&
      acumulador.gnc !== "" ? (
        <div
          className="btn-continuar-activo-tablet"
          style={{ width: "100%", marginTop: "0px", marginBottom: "0px" }}
          onClick={enviarDatos}
        >
          Cotizar
        </div>
      ) : (
        <div
          className="btn-continuar-disabled-tablet"
          style={{ width: "100%", marginTop: "0px", marginBottom: "0px" }}
        >
          Cotizar
        </div>
      )}
      {valorKms < 1000 && !open ? (
        <Modal open={openModalKmsLow} onClose={handleCloseModalKmsLow}>
          <div className="containerModalCotizar">
            <p className="title-modal-cotizar">
              El kilometraje ingresado es muy bajo
            </p>
            <p style={{ marginTop: "15px", marginBottom: "50px" }}>
              Ingresaste <b>{valorKms}km</b> ¿Es correcto?
            </p>
            <div className="containerBtnKmsLow">
              <button
                className="btnClose"
                style={{ width: "200px", height: "35px" }}
                onClick={() => {
                  setopenModalKmsLow(false);
                }}
              >
                No
              </button>
              <button
                className="btnCotizar"
                style={{ width: "200px", height: "35px" }}
                onClick={() => {
                  setopenModalKmsLow(false);
                  setOpen(true);
                }}
              >
                Si
              </button>
            </div>
          </div>
        </Modal>
      ) : (
        <ModalCotizar
          openModalManual={open}
          setopenModalManual={setOpen}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        />
      )}
    </>
  );
};
