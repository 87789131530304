import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDBtLL7dn0jxQXTW98WVfs1dWGF2vA1F3s",
  authDomain: "web-app-k.firebaseapp.com",
  projectId: "web-app-k",
  storageBucket: "web-app-k.appspot.com",
  messagingSenderId: "176316137639",
  appId: "1:176316137639:web:2fdc33c308e1c57a5e9b82",
  measurementId: "G-FTK9EMR1RJ",
};

firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const googleAuthProvider = new firebase.auth.GoogleAuthProvider();

const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();

export { db, firebase, googleAuthProvider, facebookAuthProvider };
