import { db } from "../firebase/firebase-config";
import { types } from "../types/type";
import axios from "axios";

export const getYears = async (dispatch) => {
  const resDb = await db.collection("cars_tree").get();
  let years = [];
  resDb.forEach((year) => {
    years.push(year.data().sources[0].data.year);
  });
  dispatch(startGetYears(years));
};

export const startGetYears = (years) => ({
  type: types.GetYears,
  payload: {
    years,
  },
});

export const getBrands = async (dispatch, año) => {
  axios
    .get(
      `https://pricing-auta-back-dot-admin-prod-368214.rj.r.appspot.com/quoter/get_brands?year=${año}`
    )
    .then((resp) => {
      dispatch(startGetBrands(resp.data.brands));
    })
    .catch((e) => {
      console.log(e);
    });
};

export const startGetBrands = (brands) => ({
  type: types.GetBrands,
  payload: {
    brands,
  },
});

export const startAcumuladorAño = (año) => ({
  type: types.AcumuladorAño,
  payload: {
    año,
  },
});

export const startAcumuladorMarca = (marca, id) => ({
  type: types.AcumuladorMarca,
  payload: {
    marca,
    id,
  },
});

export const startAcumuladorModelo = (modelo, id) => ({
  type: types.AcumuladorModelo,
  payload: {
    modelo,
    id,
  },
});

export const startAcumuladorVersion = (version, id) => ({
  type: types.AcumuladorVersion,
  payload: {
    version,
    id,
  },
});

export const startAcumuladorKilometros = (kilometros) => ({
  type: types.AcumuladorKilometros,
  payload: {
    kilometros,
  },
});

export const startAcumuladorGNC = (gnc) => ({
  type: types.AcumuladorGNC,
  payload: {
    gnc,
  },
});

export const getModels = async (dispatch, marca, año) => {
  axios
    .get(
      `https://pricing-auta-back-dot-admin-prod-368214.rj.r.appspot.com/quoter/get_models?year=${año}&brand=${marca}`
    )
    .then((resp) => {
      dispatch(startGetModels(resp.data.models));
    })
    .catch((e) => {
      console.log(e);
    });
};

export const startGetModels = (models) => ({
  type: types.GetModels,
  payload: {
    models,
  },
});

export const getVersion = async (dispatch, año, marca, model) => {
  axios
    .get(
      `https://pricing-auta-back-dot-admin-prod-368214.rj.r.appspot.com/quoter/get_versions?year=${año}&brand=${marca}&model=${model}`
    )
    .then((resp) => {
      dispatch(startGetVersion(resp.data.versions));
    })
    .catch((e) => {
      console.log(e);
    });
};

export const startGetVersion = (versions) => ({
  type: types.GetVersion,
  payload: {
    versions,
  },
});

export const sendKmsRedux = (valorKms) => ({
  type: types.SendKms,
  payload: {
    valorKms,
  },
});

export const postGoogleSheet = (
  brand,
  model,
  version,
  year,
  kms,
  name,
  celular,
  gnc
) => {
  const dataLanding = {
    brand: brand,
    model: model,
    version: version,
    year: year,
    gnc: gnc === "Si" ? true : false,
    kms: kms,
    name: name,
    cel: celular,
  };

  axios
    .post(
      `https://southamerica-east1-admin-prod-368214.cloudfunctions.net/log_deals_sheet`,
      dataLanding
    )
    .then((resp) => {
      console.log(resp.data);
    })
    .catch((e) => {
      console.log(e.request);
    });
};

export const enviarDatosAuto = async (
  dispatch,
  brand,
  brand_id,
  model,
  model_id,
  version,
  version_id,
  year,
  gnc,
  kms,
  telefono,
  nombre,
  setopenModalManual,
  setShowModalStopper,
  navigate
) => {
  const valorKmsFormated = parseInt(kms.replace(/\D/g, ""));

  const dataLanding = {
    brand: brand,
    brand_id: brand_id,
    model: model,
    model_id: model_id,
    version: version,
    version_id: version_id,
    year: year,
    gnc: gnc === "Si" ? true : false,
    kms: valorKmsFormated,
    name: nombre,
    cel: telefono,
    source: "landing_san_jorge",
  };

  axios
    .post(`https://infoauto-348420.rj.r.appspot.com/landing_auta`, dataLanding)
    .then((resp) => {
      if (resp.data.stopper) {
        setShowModalStopper(true);
      } else {
        navigate("/success");
      }
    })
    .catch((e) => {
      const error = JSON.parse(e.request.response);
      dispatch(startLoading(telefono, error.message, e.request.status));
      console.log(error);
    });
};

export const startLoading = (telefono, loading, status) => ({
  type: types.Loading,
  payload: {
    telefono,
    loading,
    status,
  },
});

export const setState = (set) => ({
  type: types.SetState,
  payload: { set },
});
