import { Modal, useMediaQuery } from "@mui/material";
import React from "react";
import {
  sendKmsRedux,
  startAcumuladorAño,
  startAcumuladorMarca,
  startAcumuladorModelo,
  startAcumuladorVersion,
  startGetBrands,
  startGetModels,
  startGetVersion,
  startLoading,
} from "../../actions/pasosCotizar";
import { useDispatch } from "react-redux";
import { isTablet } from "../../actions/pasosTablet";

export const ModalStopperComponent = ({
  showModalStopper,
  setShowModalStopper,
  setopenModalManual,
}) => {
  let isPageWide = useMediaQuery("(min-width: 900px)");

  const dispatch = useDispatch();

  const backToHome = () => {
    if (isPageWide) {
      dispatch(startAcumuladorAño(undefined));
      dispatch(startAcumuladorMarca(undefined));
      dispatch(startAcumuladorModelo(undefined));
      dispatch(startAcumuladorVersion(undefined));
      dispatch(sendKmsRedux(undefined));
      dispatch(startGetBrands([]));
      dispatch(startGetModels([]));
      dispatch(startGetVersion([]));
      dispatch(startLoading(undefined, false, 100));
    } else {
      dispatch(isTablet(""));
      dispatch(startAcumuladorAño(""));
      dispatch(startAcumuladorMarca(""));
      dispatch(startAcumuladorModelo(""));
      dispatch(startAcumuladorVersion(""));
      dispatch(sendKmsRedux(""));
      dispatch(startGetBrands([]));
      dispatch(startGetModels([]));
      dispatch(startGetVersion([]));
      dispatch(startLoading(undefined, false, 100));
    }
    setShowModalStopper(false);
    setopenModalManual(false);
  };

  const handleClose = () => {
    backToHome();
  };

  return (
    <>
      <Modal
        open={showModalStopper}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="modal-stopper">
          <div className="top-modal-stopper">
            <p className="title-modal-stopper">¡Ups!</p>
            <p className="description-modal-stopper">
              Lamentablemente no podemos hacer una oferta de <br /> compra por
              tu auto. Agradecemos tu interés y <br /> quedamos a disposición
              para cualquier consulta futura.
            </p>
            <p className="description-modal-stopper-mobile">
              Lamentablemente no podemos hacer una oferta de compra por tu auto.
              Agradecemos tu interés y quedamos a disposición para cualquier
              consulta futura.
            </p>
          </div>
          <div className="bottom-modal-stopper">
            <p className="footer-modal-stopper">¡Que tengas un buen día!</p>
            <button className="btn-back-to-home" onClick={backToHome}>
              Volver al inicio
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};
