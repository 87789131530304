import { types } from "../types/type";

export const validateReducer = (state = {}, action) => {
  switch (action.type) {
    case types.EditarAuto:
      return {
        ...state,
        editar: action.payload.editar,
      };
    case types.DatosAuto:
      return {
        ...state,
        marca: action.payload.marca,
        modelo: action.payload.modelo,
        version: action.payload.version,
        año: action.payload.año,
        kms: action.payload.kms,
      };
    default:
      return state;
  }
};
