import { Grid } from "@mui/material";
import Hidden from "@mui/material/Hidden";
import React from "react";
import LogoSanJorge from "../../images/logo-san-jorge.png";
import ArrowBack from "../../images/arrow-back.png";
import { Año } from "../Año/Año";
import { BotonContinuar } from "../ButtonContinuar/BotonContinuar";
import { Kilometros } from "../Kilometros/Kilometros";
import { Marca } from "../Marca/Marca";
import { Modelo } from "../Modelo/Modelo";
import { Version } from "../Version/Version";
import "./Cotizar.css";
import { useSelector, useDispatch } from "react-redux";
import {
  startAcumuladorAño,
  startAcumuladorMarca,
  startAcumuladorModelo,
  startAcumuladorVersion,
} from "../../actions/pasosCotizar";
import "animate.css";
import { ModalCarManual } from "../ModalManual/ModalCarManual";
import { useParams } from "react-router-dom";
import { ModalStopperComponent } from "../ModalStopper/ModalStopperComponent";

export const Cotizar = () => {
  const dispatch = useDispatch();
  const { acumulador } = useSelector((state) => state.inputReducer);
  const [open, setOpen] = React.useState(false);
  const { deal_id } = useParams();

  const backStepOne = () => {
    dispatch(startAcumuladorAño(""));
  };

  const backStepTwo = () => {
    dispatch(startAcumuladorMarca(""));
  };

  const backStepTres = () => {
    dispatch(startAcumuladorModelo(""));
    dispatch(startAcumuladorVersion(""));
  };

  const backStepFour = () => {
    dispatch(startAcumuladorVersion(""));
  };

  return (
    <>
      <Grid
        direction="row"
        alignItems="center"
        justifyContent="center"
        className="container-cotizar"
      >
        <Grid xs={12} container alignItems="center" justifyContent="center">
          <img
            src={LogoSanJorge}
            alt="logo-san-jorge"
            className="logo-san-jorge"
          />
        </Grid>
        <Grid
          xs={12}
          container
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <p className="text-cotiza">Cotizá tu auto</p>
          <p className="text-ingresa">
            Ingresá los datos y encontrá la mejor oferta para tu auto
          </p>
          <Hidden mdUp>
            {acumulador !== undefined &&
            acumulador.año !== undefined &&
            acumulador.año !== "" ? (
              <>
                {acumulador !== undefined &&
                acumulador.año !== undefined &&
                acumulador.marca !== undefined &&
                acumulador.marca !== "" ? (
                  <>
                    {acumulador !== undefined &&
                    acumulador.año !== undefined &&
                    acumulador.marca !== undefined &&
                    acumulador.marca !== "" &&
                    acumulador.modelo !== undefined &&
                    acumulador.modelo !== "" ? (
                      <>
                        {acumulador !== undefined &&
                        acumulador.año !== undefined &&
                        acumulador.año !== "" &&
                        acumulador.marca !== undefined &&
                        acumulador.marca !== "" &&
                        acumulador.modelo !== undefined &&
                        acumulador.modelo !== "" &&
                        acumulador.version !== undefined &&
                        acumulador.version !== "" ? (
                          <div className="container-paso-arrow">
                            <img
                              src={ArrowBack}
                              alt="arrow-back"
                              className="arrow-back"
                              onClick={backStepFour}
                            />
                            <p className="text-steps">
                              Paso <strong>5</strong> de <strong>5</strong>
                            </p>
                          </div>
                        ) : (
                          <div className="container-paso-arrow">
                            <img
                              src={ArrowBack}
                              alt="arrow-back"
                              className="arrow-back"
                              onClick={backStepTres}
                            />
                            <p className="text-steps">
                              Paso <strong>4</strong> de <strong>5</strong>
                            </p>
                          </div>
                        )}
                      </>
                    ) : (
                      <div className="container-paso-arrow">
                        <img
                          src={ArrowBack}
                          alt="arrow-back"
                          className="arrow-back"
                          onClick={backStepTwo}
                        />
                        <p className="text-steps">
                          Paso <strong>3</strong> de <strong>5</strong>
                        </p>
                      </div>
                    )}
                  </>
                ) : (
                  <div className="container-paso-arrow">
                    <img
                      src={ArrowBack}
                      alt="arrow-back"
                      className="arrow-back"
                      onClick={backStepOne}
                    />
                    <p className="text-steps">
                      Paso <strong>2</strong> de <strong>5</strong>
                    </p>
                  </div>
                )}
              </>
            ) : (
              <div className="container-paso-arrow">
                <p className="text-steps">
                  Paso <strong>1</strong> de <strong>5</strong>
                </p>
              </div>
            )}
          </Hidden>
        </Grid>
        <Hidden mdDown>
          <Grid
            item
            xs={12}
            container
            alignItems="start"
            justifyContent="space-evenly"
            style={{
              marginTop: "30px",
              height: "50vh",
            }}
          >
            <Año />
            <Marca />
            <Modelo />
            <Version />
            <Kilometros />
            <BotonContinuar deal_id={deal_id} />
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <Grid
            item
            xs={12}
            container
            alignItems="start"
            justifyContent="space-evenly"
            style={{ marginTop: "25px", height: "45vh" }}
          >
            {acumulador !== undefined &&
            acumulador.año !== undefined &&
            acumulador.año !== "" ? (
              <>
                {acumulador !== undefined &&
                acumulador.año !== undefined &&
                acumulador.año !== "" &&
                acumulador.marca !== undefined &&
                acumulador.marca !== "" ? (
                  <>
                    {acumulador !== undefined &&
                    acumulador.año !== undefined &&
                    acumulador.año !== "" &&
                    acumulador.marca !== undefined &&
                    acumulador.marca !== "" &&
                    acumulador.modelo !== undefined &&
                    acumulador.modelo !== "" ? (
                      <>
                        {acumulador !== undefined &&
                        acumulador.año !== undefined &&
                        acumulador.año !== "" &&
                        acumulador.marca !== undefined &&
                        acumulador.marca !== "" &&
                        acumulador.modelo !== undefined &&
                        acumulador.modelo !== "" &&
                        acumulador.version !== undefined &&
                        acumulador.version !== "" ? (
                          <Kilometros />
                        ) : (
                          <>
                            <Version />
                            <Kilometros />
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <Modelo />
                        <Version />
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <Marca />
                    <Modelo />
                  </>
                )}
              </>
            ) : (
              <>
                <Año />
                <Marca />
              </>
            )}
          </Grid>
        </Hidden>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          style={{
            marginTop: "20px",
          }}
        >
          <div style={{ cursor: "pointer" }}>
            <a
              href="https://wa.me/+5491138876132" // Reemplaza con el número de teléfono de WhatsApp
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none" }}
            >
              <p className="text-footer">¿No logras cotizar tu vehiculo?</p>
              <p className="text-footer-two">
                Consulta con un asesor y recibí tu cotización.{" "}
              </p>
            </a>
          </div>
          <ModalCarManual open={open} setOpen={setOpen} />
          <ModalStopperComponent />
        </Grid>
      </Grid>
    </>
  );
};
