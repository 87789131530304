import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendKmsRedux, startAcumuladorGNC } from "../../actions/pasosCotizar";
import { BotonContinuarTablet } from "../ButtonContinuar/BotonContinuarTablet";
import Hidden from "@mui/material/Hidden";
import { FormControlLabel, Grid, Radio, RadioGroup } from "@mui/material";
import axios from "axios";
import { ModalStopperComponent } from "../ModalStopper/ModalStopperComponent";
import { Form } from "react-bootstrap";

export const Kilometros = () => {
  const { acumulador, valorKms } = useSelector((state) => state.inputReducer);

  const dispatch = useDispatch();

  const [mostrarSelect, setmostrarSelect] = useState(false);
  const [showModalStopper, setShowModalStopper] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [valueKms, setValueKms] = useState();
  const [isGnc, setIsGnc] = useState(""); // Estado para el valor seleccionado

  const mostrarOpciones = () => {
    setmostrarSelect(!mostrarSelect);
  };

  const handleOptionChange = (event) => {
    setIsGnc(event.target.value);
    axios
      .get(
        `https://pricing-auta-back-dot-admin-prod-368214.rj.r.appspot.com/quoter/is_stopper?idAuta=${
          acumulador.version_id
        }&kms=${valueKms}&gnc=${event.target.value === "No" ? false : true}`
      )
      .then((resp) => {
        if (resp.data.stopper === true) {
          setShowModalStopper(true);
        } else {
          dispatch(startAcumuladorGNC(event.target.value));
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value.replace(/\D/g, ""); // Elimina caracteres no numéricos
    const formattedNumber = inputValue.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    const intValue = parseInt(inputValue.replace(/\./g, ""), 10); // Convierte a entero y elimina separadores de miles
    setValueKms(intValue);
    dispatch(sendKmsRedux(formattedNumber));
    dispatch(startAcumuladorGNC(undefined));
    setIsGnc("");
  };

  return (
    <>
      <ModalStopperComponent
        showModalStopper={showModalStopper}
        setShowModalStopper={setShowModalStopper}
        setopenModalManual={setOpen}
      />
      <div className="container-input-scroll">
        {acumulador !== undefined &&
        acumulador.version !== undefined &&
        acumulador.version !== "" ? (
          <>
            {valorKms !== undefined && valorKms !== "" ? (
              <>
                <Hidden mdDown>
                  <div
                    className="btn-hecho animate__fadeIn"
                    onClick={mostrarOpciones}
                  >
                    {valorKms} kms
                  </div>
                </Hidden>
                <Hidden mdUp>
                  <div
                    className="btn-hecho animate__fadeIn"
                    style={{ width: "160px", height: "33px", fontSize: "16px" }}
                    onClick={mostrarOpciones}
                  >
                    {valorKms} kms
                  </div>
                </Hidden>
              </>
            ) : (
              <>
                <Hidden mdDown>
                  <div
                    className="btn-activo animate__fadeIn"
                    onClick={mostrarOpciones}
                  >
                    Kilometros
                  </div>
                </Hidden>
                <Hidden mdUp>
                  <div
                    className="btn-activo animate__fadeIn"
                    style={{ width: "160px", height: "33px", fontSize: "16px" }}
                    onClick={mostrarOpciones}
                  >
                    Kilometros
                  </div>
                </Hidden>
              </>
            )}
          </>
        ) : (
          <>
            <Hidden mdDown>
              <div className="btn-disabled animate__fadeIn">Kilometros</div>
            </Hidden>
            <Hidden mdUp>
              <div
                className="btn-disabled animate__fadeIn"
                style={{ width: "160px", height: "33px", fontSize: "16px" }}
              >
                Kilometros
              </div>
            </Hidden>
          </>
        )}
        {mostrarSelect ||
        (acumulador !== undefined &&
          acumulador.version !== undefined &&
          acumulador.version !== "") ? (
          <>
            <Hidden mdDown>
              <div
                className="input-kilometros"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "auto",
                }}
              >
                <input
                  type="text"
                  value={valorKms}
                  className="kms-input"
                  onChange={handleInputChange}
                  placeholder="Ej: 24000"
                  style={{ marginTop: "15px", marginBottom: "15px" }}
                />
                <p className="labelModalManual">¿Tiene o tuvo GNC?</p>
                <div className="radio-button-container">
                  <Form.Check
                    className="radio-button-input"
                    type="radio"
                    label="Sí"
                    value="Si"
                    checked={isGnc === "Si"}
                    onChange={handleOptionChange}
                  />
                  <Form.Check
                    className="radio-button-input"
                    type="radio"
                    label="No"
                    value="No"
                    checked={isGnc === "No"}
                    onChange={handleOptionChange}
                  />
                </div>
              </div>
            </Hidden>
            <Hidden mdUp>
              <div className="input-kilometros-tablet">
                <input
                  className="input-kms-tablet"
                  type="text"
                  value={valorKms}
                  onChange={handleInputChange}
                  placeholder="Ej: 24000"
                />
                <p
                  className="labelModalManual"
                  style={{ fontSize: "12px", marginTop: "10px" }}
                >
                  ¿Tiene o tuvo GNC?
                </p>
                <div className="radio-button-container">
                  <Form.Check
                    className="radio-button-input"
                    type="radio"
                    label="Sí"
                    value="Si"
                    checked={isGnc === "Si"}
                    onChange={handleOptionChange}
                  />
                  <Form.Check
                    className="radio-button-input"
                    type="radio"
                    label="No"
                    value="No"
                    checked={isGnc === "No"}
                    onChange={handleOptionChange}
                  />
                </div>
                <BotonContinuarTablet />
              </div>
            </Hidden>
          </>
        ) : null}
      </div>
    </>
  );
};
